import { useState } from "react";
import FinanzenEBITBarLineChart from "./charts/FinanzenEBITBarLineChart";
import FinanzenEBITLineBarChart from "./charts/FinanzenEBITLineBarChart";
import FinanzenKostenStackedBarChart from "./charts/FinanzenKostenStackedBarChart";
import FinanzenUmsatzLineBarChart from "./charts/FinanzenUmsatzLineBarChart";

function FinanzenMainAllTeams() {
    const [selectedFirstTeam, setSelectedFirstTeam] = useState('Team 1');
    const [selectedSecondTeam, setSelectedSecondTeam] = useState('Team 2');

    const handleChangeFirstTeamSelect = event => {
        setSelectedFirstTeam(event.target.value);
    }

    const handleChangeSecondTeamSelect = event => {
        setSelectedSecondTeam(event.target.value);
    }
    return (
        <div className="main">
            <div className="grid">
                <select value={selectedFirstTeam} className="select-left" onChange={handleChangeFirstTeamSelect}>
                    <option value="Team 1">Team 1</option>
                    <option value="Team 2">Team 2</option>
                    <option value="Team 3">Team 3</option>
                    <option value="Team 4">Team 4</option>
                </select>
                <select value={selectedSecondTeam} className="select-right" onChange={handleChangeSecondTeamSelect}>
                    <option value="Team 1">Team 1</option>
                    <option value="Team 2">Team 2</option>
                    <option value="Team 3">Team 3</option>
                    <option value="Team 4">Team 4</option>
                </select>
                <FinanzenUmsatzLineBarChart selectedTeam={selectedFirstTeam} />
                <FinanzenUmsatzLineBarChart selectedTeam={selectedSecondTeam} />
                <FinanzenKostenStackedBarChart selectedTeam={selectedFirstTeam} />
                <FinanzenKostenStackedBarChart selectedTeam={selectedSecondTeam} />
                <FinanzenEBITLineBarChart selectedTeam={selectedFirstTeam} />
                <FinanzenEBITLineBarChart selectedTeam={selectedSecondTeam} />
                <FinanzenEBITBarLineChart selectedTeam={selectedFirstTeam} />
                <FinanzenEBITBarLineChart selectedTeam={selectedSecondTeam} />
            </div>
        </div>
    );
}

export default FinanzenMainAllTeams;