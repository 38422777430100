import { Routes, Route } from 'react-router-dom'
import './App.css';
import Home from './pages/Home';
import Finanzen from './pages/Finanzen';
import Mitarbeiter from './pages/Mitarbeiter';
import Loader from './components/Loader'
import { useAuth0 } from '@auth0/auth0-react'
import ProtectedRoute from './components/authentication/ProtectedRoute';

function App() {

  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loader/>;
  }

  return (
    <div className="app">
          <div className="pages">
            <Routes>
              <Route 
                path="/" 
                element={<Home />} 
              />
              <Route 
                path="/finanzen" 
                element={<ProtectedRoute component={Finanzen} />} 
              />
              <Route 
                path="/mitarbeiter" 
                element={<ProtectedRoute component={Mitarbeiter} />} 
              />
            </Routes>
          </div>
    </div>
  );
}

export default App;
