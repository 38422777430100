import { useAuth0 } from '@auth0/auth0-react';

function Loader() {
  const {
      isLoading,
  } = useAuth0();

  if (isLoading) {
    return (
      <div className="preloader">
          <div className="loading">
              <img className="logo" alt="Logo brandung GmbH" src="https://www.agentur-brandung.de//static/logo_brandung.svg" />
              <span className="progress">
              </span>
          </div>
      </div>
    );
  }
}

export default Loader;
