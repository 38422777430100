import MitarbeiterLineChart from "./charts/MitarbeiterLineChart";
import MitarbeiterRadarChart from "./charts/MitarbeiterRadarChart";


//TODO Team Durchschnittsvergleich
function MitarbeiterMainSingleTeam(props) {
    return (
        <div className="main">
            <div className="grid">
                <MitarbeiterLineChart selectedTeam={props.leadedTeam}/>
                <MitarbeiterRadarChart selectedFirstTeam={props.leadedTeam} selectedSecondTeam="Durschschnitt der anderen Teams"/>
            </div>
        </div>
    );
}

export default MitarbeiterMainSingleTeam;