import { useState } from "react";
import MitarbeiterLineChart from "./charts/MitarbeiterLineChart";
import MitarbeiterRadarChart from "./charts/MitarbeiterRadarChart";

function MitarbeiterMainAllTeams() {
    const [selectedFirstTeam, setSelectedFirstTeam] = useState('Team 1');
    const [selectedSecondTeam, setSelectedSecondTeam] = useState('Team 2');

    const handleChangeFirstTeamSelect = event => {
        setSelectedFirstTeam(event.target.value);
    }

    const handleChangeSecondTeamSelect = event => {
        setSelectedSecondTeam(event.target.value);
    }
    return (
        <div className="main">
            <div className="grid">
                <select value={selectedFirstTeam} className="select-left" onChange={handleChangeFirstTeamSelect}>
                    <option value="Team 1">Team 1</option>
                    <option value="Team 2">Team 2</option>
                    <option value="Team 3">Team 3</option>
                    <option value="Team 4">Team 4</option>
                </select>
                <select value={selectedSecondTeam} className="select-right" onChange={handleChangeSecondTeamSelect}>
                    <option value="Team 1">Team 1</option>
                    <option value="Team 2">Team 2</option>
                    <option value="Team 3">Team 3</option>
                    <option value="Team 4">Team 4</option>
                </select>
                <MitarbeiterLineChart selectedTeam={selectedFirstTeam}/>
                <MitarbeiterLineChart selectedTeam={selectedSecondTeam}/>
            </div>
            <div className='singlechart-container'>
                <div className="singlechart">
                    <MitarbeiterRadarChart selectedFirstTeam={selectedFirstTeam} selectedSecondTeam={selectedSecondTeam}/>
                </div>
            </div>
        </div>
    );
}

export default MitarbeiterMainAllTeams;