import { useState, useEffect } from 'react'
import jwt from 'jwt-decode'
import { useAuth0 } from "@auth0/auth0-react";
import Header from '../components/Header';
import FinanzenMainSingleTeam from '../components/FinanzenMainSingleTeam';
import FinanzenMainAllTeams from '../components/FinanzenMainAllTeams';

const Finanzen = () => {
  const [permissions, setPermissions] = useState("");
  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        const data = jwt(accessToken)
        setPermissions(data.permissions);
    }
    getAccessToken()
  }, []);
  if(permissions[0] === "allTeams") {
    return (
      <div className="finanzen">
        <Header />
        <FinanzenMainAllTeams />
      </div>
    )
  }
  else if(permissions[0] === "singleTeam") {
    return (
      <div className="finanzen">
        <Header />
        <FinanzenMainSingleTeam leadedTeam={permissions[1]}/>
      </div>
    )
  }  
}
  
export default Finanzen