import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import logoMin from '../assets/img/logo-min.svg';
import logo from '../assets/img/logo.svg';

const HomeHeader = () => {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    if(isAuthenticated) {
        return (
            <div className="header">
                <Link to="/" className="logo-container">
                    <img className="logo-min" alt="Logo brandung GmbH" src={logoMin} />
                    <img className="logo" alt="Logo brandung GmbH" src={logo} />
                </Link>
                <div className="button" onClick={() => logout({returnTo: window.location.origin})}>Logout</div>
            </div>
        )
    }
    else {
        return (
            <div className="header">
                <Link to="/" className="logo-container">
                    <img className="logo-min" alt="Logo brandung GmbH" src={logoMin} />
                    <img className="logo" alt="Logo brandung GmbH" src={logo} />
                </Link>
                <div className="button" onClick={loginWithRedirect}>Login</div>
            </div>
        )
    }
}

export default HomeHeader