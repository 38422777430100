import FinanzenEBITBarLineChart from "./charts/FinanzenEBITBarLineChart";
import FinanzenEBITLineBarChart from "./charts/FinanzenEBITLineBarChart";
import FinanzenKostenStackedBarChart from "./charts/FinanzenKostenStackedBarChart";
import FinanzenUmsatzLineBarChart from "./charts/FinanzenUmsatzLineBarChart";

function FinanzenMainSingleTeam(props) {
    return (
        <div className="main">
            <div className="grid">
                <FinanzenUmsatzLineBarChart selectedTeam={props.leadedTeam} />
                <FinanzenKostenStackedBarChart selectedTeam={props.leadedTeam} />
                <FinanzenEBITLineBarChart selectedTeam={props.leadedTeam} />
                <FinanzenEBITBarLineChart selectedTeam={props.leadedTeam} />
            </div>
        </div>
    );
}

export default FinanzenMainSingleTeam;