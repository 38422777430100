import { useState, useEffect, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import Loader from '../Loader'
import { useAuth0 } from "@auth0/auth0-react";
import { io } from 'socket.io-client';
const socket = io(process.env.REACT_APP_SOCKET_URL);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

function MitarbeiterLineChart(props) {
    const { getAccessTokenSilently } = useAuth0();
    const chartRef = useRef(null);
    const [fetchedData, setFetchedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState('2022');
    const [selectedFirstCategory, setSelectedFirstCategory] = useState('Workload');
    const [selectedSecondCategory, setSelectedSecondCategory] = useState('Gesamt');

    const handleChangeYearSelect = event => {
        setSelectedYear(event.target.value);
    }

    const handleChangeFirstCategorySelect = event => {
        setSelectedFirstCategory(event.target.value);
    }

    const handleChangeSecondCategorySelect = event => {
        setSelectedSecondCategory(event.target.value);
    }

    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        FetchData(accessToken);
    }

    const FetchData = (accessToken) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}?attributes=workload,teamAtmosphere,customerSatisfaction,processQuality,overallTeam&dates=false`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((actualData) => {
                setFetchedData(actualData);
                setError(null);
            })
            .catch((err) => {
                setError(err.message);
                setFetchedData(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getAccessToken();
        socket.on('update', update => {
            getAccessToken();
        });
    }, []);

    if (loading || error) {
        return (
            <div>
                {loading && <Loader />}
                {error && (
                    <div>{`There is a problem fetching the post data - ${error}`}</div>
                )}
            </div>
        )
    }
    else {
        let dataSelectedTeam = [];
        let dataSelectedFirstCategory = [];
        let dataSelectedSecondCategory = [];

        switch (props.selectedTeam) {
            case "Team 1":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[0].workload.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[0].teamAtmosphere.slice(0, 12);
                        dataSelectedTeam[2] = fetchedData[0].customerSatisfaction.slice(0, 12);
                        dataSelectedTeam[3] = fetchedData[0].processQuality.slice(0, 12);
                        dataSelectedTeam[4] = fetchedData[0].overallTeam.slice(0, 12);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[0].workload.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[0].teamAtmosphere.slice(12, 24);
                        dataSelectedTeam[2] = fetchedData[0].customerSatisfaction.slice(12, 24);
                        dataSelectedTeam[3] = fetchedData[0].processQuality.slice(12, 24);
                        dataSelectedTeam[4] = fetchedData[0].overallTeam.slice(12, 24);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[0].workload.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[0].teamAtmosphere.slice(24, 36);
                        dataSelectedTeam[2] = fetchedData[0].customerSatisfaction.slice(24, 36);
                        dataSelectedTeam[3] = fetchedData[0].processQuality.slice(24, 36);
                        dataSelectedTeam[4] = fetchedData[0].overallTeam.slice(24, 36);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[0].workload.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[0].teamAtmosphere.slice(36, 48);
                        dataSelectedTeam[2] = fetchedData[0].customerSatisfaction.slice(36, 48);
                        dataSelectedTeam[3] = fetchedData[0].processQuality.slice(36, 48);
                        dataSelectedTeam[4] = fetchedData[0].overallTeam.slice(36, 48);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    default:
                }
                break;
            case "Team 2":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[1].workload.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[1].teamAtmosphere.slice(0, 12);
                        dataSelectedTeam[2] = fetchedData[1].customerSatisfaction.slice(0, 12);
                        dataSelectedTeam[3] = fetchedData[1].processQuality.slice(0, 12);
                        dataSelectedTeam[4] = fetchedData[1].overallTeam.slice(0, 12);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[1].workload.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[1].teamAtmosphere.slice(12, 24);
                        dataSelectedTeam[2] = fetchedData[1].customerSatisfaction.slice(12, 24);
                        dataSelectedTeam[3] = fetchedData[1].processQuality.slice(12, 24);
                        dataSelectedTeam[4] = fetchedData[1].overallTeam.slice(12, 24);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[1].workload.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[1].teamAtmosphere.slice(24, 36);
                        dataSelectedTeam[2] = fetchedData[1].customerSatisfaction.slice(24, 36);
                        dataSelectedTeam[3] = fetchedData[1].processQuality.slice(24, 36);
                        dataSelectedTeam[4] = fetchedData[1].overallTeam.slice(24, 36);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[1].workload.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[1].teamAtmosphere.slice(36, 48);
                        dataSelectedTeam[2] = fetchedData[1].customerSatisfaction.slice(36, 48);
                        dataSelectedTeam[3] = fetchedData[1].processQuality.slice(36, 48);
                        dataSelectedTeam[4] = fetchedData[1].overallTeam.slice(36, 48);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    default:
                }
                break;
            case "Team 3":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[2].workload.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[2].teamAtmosphere.slice(0, 12);
                        dataSelectedTeam[2] = fetchedData[2].customerSatisfaction.slice(0, 12);
                        dataSelectedTeam[3] = fetchedData[2].processQuality.slice(0, 12);
                        dataSelectedTeam[4] = fetchedData[2].overallTeam.slice(0, 12);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[2].workload.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[2].teamAtmosphere.slice(12, 24);
                        dataSelectedTeam[2] = fetchedData[2].customerSatisfaction.slice(12, 24);
                        dataSelectedTeam[3] = fetchedData[2].processQuality.slice(12, 24);
                        dataSelectedTeam[4] = fetchedData[2].overallTeam.slice(12, 24);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[2].workload.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[2].teamAtmosphere.slice(24, 36);
                        dataSelectedTeam[2] = fetchedData[2].customerSatisfaction.slice(24, 36);
                        dataSelectedTeam[3] = fetchedData[2].processQuality.slice(24, 36);
                        dataSelectedTeam[4] = fetchedData[2].overallTeam.slice(24, 36);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[2].workload.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[2].teamAtmosphere.slice(36, 48);
                        dataSelectedTeam[2] = fetchedData[2].customerSatisfaction.slice(36, 48);
                        dataSelectedTeam[3] = fetchedData[2].processQuality.slice(36, 48);
                        dataSelectedTeam[4] = fetchedData[2].overallTeam.slice(36, 48);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    default:
                }
                break;
            case "Team 4":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[3].workload.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[3].teamAtmosphere.slice(0, 12);
                        dataSelectedTeam[2] = fetchedData[3].customerSatisfaction.slice(0, 12);
                        dataSelectedTeam[3] = fetchedData[3].processQuality.slice(0, 12);
                        dataSelectedTeam[4] = fetchedData[3].overallTeam.slice(0, 12);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[3].workload.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[3].teamAtmosphere.slice(12, 24);
                        dataSelectedTeam[2] = fetchedData[3].customerSatisfaction.slice(12, 24);
                        dataSelectedTeam[3] = fetchedData[3].processQuality.slice(12, 24);
                        dataSelectedTeam[4] = fetchedData[3].overallTeam.slice(12, 24);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[3].workload.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[3].teamAtmosphere.slice(24, 36);
                        dataSelectedTeam[2] = fetchedData[3].customerSatisfaction.slice(24, 36);
                        dataSelectedTeam[3] = fetchedData[3].processQuality.slice(24, 36);
                        dataSelectedTeam[4] = fetchedData[3].overallTeam.slice(24, 36);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[3].workload.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[3].teamAtmosphere.slice(36, 48);
                        dataSelectedTeam[2] = fetchedData[3].customerSatisfaction.slice(36, 48);
                        dataSelectedTeam[3] = fetchedData[3].processQuality.slice(36, 48);
                        dataSelectedTeam[4] = fetchedData[3].overallTeam.slice(36, 48);

                        switch (selectedFirstCategory) {
                            case "Workload":
                                dataSelectedFirstCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedFirstCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedFirstCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedFirstCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedFirstCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        switch (selectedSecondCategory) {
                            case "Workload":
                                dataSelectedSecondCategory = dataSelectedTeam[0];
                                break;
                            case "Stimmung im Team":
                                dataSelectedSecondCategory = dataSelectedTeam[1];
                                break;
                            case "Kundenzufriedenheit":
                                dataSelectedSecondCategory = dataSelectedTeam[2];
                                break;
                            case "Prozessqualität":
                                dataSelectedSecondCategory = dataSelectedTeam[3];
                                break;
                            case "Gesamt":
                                dataSelectedSecondCategory = dataSelectedTeam[4];
                                break;
                            default:
                        }
                        break;
                    default:
                }
                break;
            default:
        }

        const data = {
            labels: ['Jan.', 'Feb.', 'Mrz.', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'],
            datasets: [
                {
                    label: selectedFirstCategory,
                    data: dataSelectedFirstCategory,
                    borderColor: 'rgb(228, 35, 33, 1)',
                    backgroundColor: 'rgba(228, 35, 33, 0.5)',
                },
                {
                    label: selectedSecondCategory,
                    data: dataSelectedSecondCategory,
                    borderColor: 'rgba(3, 141, 175, 1)',
                    backgroundColor: 'rgba(3, 141, 175, 0.5)',
                }
            ],
        };

        const options = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                    }
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Monat',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Net Promoter Score',
                    },
                    min: 0,
                    max: 10,
                    ticks: {
                        stepSize: 1,
                    }
                }
            },
        }
        return (
            <div className="card">
                <div className="card-header">
                    <p>Attribute {props.selectedTeam}</p>
                    <div>
                        <select value={selectedYear} onChange={handleChangeYearSelect}>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                        </select>
                        <div className="select-list">
                            <select value={selectedFirstCategory} onChange={handleChangeFirstCategorySelect}>
                                <option value="Workload">Workload</option>
                                <option value="Stimmung im Team">Stimmung im Team</option>
                                <option value="Kundenzufriedenheit">Kundenzufriedenheit</option>
                                <option value="Prozessqualität">Prozessqualität</option>
                                <option value="Gesamt">Gesamt</option>
                            </select>
                            <select value={selectedSecondCategory} onChange={handleChangeSecondCategorySelect}>
                                <option value="Workload">Workload</option>
                                <option value="Stimmung im Team">Stimmung im Team</option>
                                <option value="Kundenzufriedenheit">Kundenzufriedenheit</option>
                                <option value="Prozessqualität">Prozessqualität</option>
                                <option value="Gesamt">Gesamt</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="chart-area">
                        <Line ref={chartRef} data={data} options={options} />
                    </div>
                </div>
            </div>
        )
    }
}

export default MitarbeiterLineChart;