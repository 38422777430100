import HomeHeader from "../components/HomeHeader"
import HomeMain from "../components/HomeMain"

const Home = () => {
    return (
      <div className="home">
        <HomeHeader />
        <HomeMain />
      </div>
    )
  }
  
  export default Home