import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';

const HomeMain = () => {
    const {
        user,
        isAuthenticated,
    } = useAuth0();
    if(isAuthenticated) {
        return (
            <div className="main">
                <div className="hero">
                    <h1>
                        Willkommen&nbsp;
                        <span className='red'>
                            {user.name}
                        </span>
                        !
                    </h1>
                    <div className="button-list">
                        <div className="button-item">
                            <Link
                                to="/finanzen"
                                className="button-link"
                            >
                                Finanzen
                            </Link>
                        </div>
                        <div className="button-item">
                            <Link
                                to="/mitarbeiter"
                                className="button-link"
                            >
                                Mitarbeiter
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="main">
            </div>
        )
    }

  }
  
  export default HomeMain