import { Link, NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import logoMin from '../assets/img/logo-min.svg';
import logo from '../assets/img/logo.svg';

const Header = () => {
    const {
        logout,
    } = useAuth0();

    return (
        <div className="header">
            <Link to="/" className="logo-container">
                <img className="logo-min" alt="Logo brandung GmbH" src={logoMin} />
                <img className="logo" alt="Logo brandung GmbH" src={logo} />
            </Link>
            <ul className="menu">
                <li className="menu-item">
                    <NavLink to="/finanzen" className={(navData) => (navData.isActive ? "menu-link active" : 'menu-link')}>Finanzen</NavLink>
                </li>
                <li className="menu-item">
                    <NavLink to="/mitarbeiter" className={(navData) => (navData.isActive ? "menu-link active" : 'menu-link')}>Mitarbeiter</NavLink>
                </li>
            </ul>
            <div className="button" onClick={() => logout({returnTo: window.location.origin})}>Logout</div>
        </div>
    )
}

export default Header