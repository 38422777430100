import { useState, useEffect } from 'react'
import jwt from 'jwt-decode'
import { useAuth0 } from "@auth0/auth0-react";
import Header from '../components/Header';
import MitarbeiterMainSingleTeam from '../components/MitarbeiterMainSingleTeam';
import MitarbeiterMainAllTeams from '../components/MitarbeiterMainAllTeams';

const Mitarbeiter = () => {
  const [permissions, setPermissions] = useState("");
  const {
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
          audience: "https://express.sample"
        });
        const data = jwt(accessToken)
        setPermissions(data.permissions);
    }
    getAccessToken()
  }, []);
  if(permissions[0] === "allTeams") {
    return (
      <div className="mitarbeiter">
        <Header />
        <MitarbeiterMainAllTeams />
      </div>
    )
  }
  else if(permissions[0] === "singleTeam") {
    return (
      <div className="mitarbeiter">
        <Header />
        <MitarbeiterMainSingleTeam leadedTeam={permissions[1]}/>
      </div>
    )
  }  
}
  
export default Mitarbeiter