import { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import Loader from '../Loader'
import { useAuth0 } from "@auth0/auth0-react";
import { io } from 'socket.io-client';
const socket = io(process.env.REACT_APP_SOCKET_URL);

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

function MitarbeiterRadarChart(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [fetchedData, setFetchedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState('2022');

    const handleChangeYearSelect = event => {
        setSelectedYear(event.target.value);
    }

    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        FetchData(accessToken);
    }

    const FetchData = (accessToken) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}?attributes=overallTeam&dates=false`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((actualData) => {
                setFetchedData(actualData);
                setError(null);
            })
            .catch((err) => {
                setError(err.message);
                setFetchedData(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getAccessToken();
        socket.on('update', update => {
            getAccessToken();
        });
    }, []);

    if (loading || error) {
        return (
            <div>
                {loading && <Loader />}
                {error && (
                    <div>{`There is a problem fetching the post data - ${error}`}</div>
                )}
            </div>
        )
    }
    else {
        let dataSelectedTeam = [];

        switch (props.selectedFirstTeam) {
            case "Team 1":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[0].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[0].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[0].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[0].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            case "Team 2":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[1].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[1].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[1].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[1].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            case "Team 3":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[2].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[2].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[2].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[2].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            case "Team 4":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[3].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[3].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[3].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[3].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            default:
        }
        switch (props.selectedSecondTeam) {
            case "Team 1":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[1] = fetchedData[0].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[1] = fetchedData[0].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[1] = fetchedData[0].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[1] = fetchedData[0].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            case "Team 2":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[1] = fetchedData[1].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[1] = fetchedData[1].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[1] = fetchedData[1].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[1] = fetchedData[1].overallTeam.slice(12, 16);
                        break;
                }
                break;
            case "Team 3":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[1] = fetchedData[2].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[1] = fetchedData[2].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[1] = fetchedData[2].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[1] = fetchedData[2].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            case "Team 4":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[1] = fetchedData[3].overallTeam.slice(0, 4);
                        break;
                    case "2020":
                        dataSelectedTeam[1] = fetchedData[3].overallTeam.slice(4, 8);
                        break;
                    case "2021":
                        dataSelectedTeam[1] = fetchedData[3].overallTeam.slice(8, 12);
                        break;
                    case "2022":
                        dataSelectedTeam[1] = fetchedData[3].overallTeam.slice(12, 16);
                        break;
                    default:
                }
                break;
            case "Durschschnitt der anderen Teams":
                dataSelectedTeam[1] = [10, 10, 10, 10];
                break;
            default:
        }

        const data = {
            labels: ['Workload', 'Stimmung im Team', 'Kundenzufriedenheit', 'Prozessqualität'],
            datasets: [
                {
                    label: props.selectedFirstTeam,
                    backgroundColor: 'rgba(228, 35, 33, 1)',
                    borderColor: 'rgba(228, 35, 33, 1)',
                    fill: false,
                    data: dataSelectedTeam[0],
                },
                {
                    label: props.selectedSecondTeam,
                    backgroundColor: 'rgba(3, 141, 175, 1)',
                    borderColor: 'rgba(3, 141, 175, 1)',
                    fill: false,
                    data: dataSelectedTeam[1],
                }
            ],
        };

        const options = {
            maintainAspectRatio: false,

            responsive: true,
            scales: {
                r: {
                    min: 0,
                    max: 10,
                    ticks: {
                        stepSize: 1,
                    }
                }
            },
        }
        return (
            <div className="card">
                <div className="card-header">
                    <p>Übersicht {props.selectedFirstTeam + " & " + props.selectedSecondTeam}</p>
                    <select value={selectedYear} onChange={handleChangeYearSelect}>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                </div>
                <div className="card-body">
                    <div className="chart-area">
                        <Radar data={data} options={options} />
                    </div>
                </div>
            </div>
        )
    }
}

export default MitarbeiterRadarChart;
