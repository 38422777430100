import {
    useState,
    useEffect
} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import Loader from '../Loader'
import { useAuth0 } from "@auth0/auth0-react";
import { io } from 'socket.io-client';
const socket = io(process.env.REACT_APP_SOCKET_URL);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y;
    });
    return 'Gesamtkosten: ' + sum;
};

function FinanzenKostenStackedBarChart(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [fetchedData, setFetchedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedYear, setSelectedYear] = useState('2022');
    let chartMax;

    const handleChangeYearSelect = event => {
        setSelectedYear(event.target.value);
    }

    const getAccessToken = async () => {
        const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        FetchData(accessToken);
    }

    const FetchData = (accessToken) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}?attributes=costs,freelancer&dates=false`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then((actualData) => {
                setFetchedData(actualData);
                setError(null);
            })
            .catch((err) => {
                setError(err.message);
                setFetchedData(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getAccessToken();
        socket.on('update', update => {
            getAccessToken();
        });
    }, []);

    if (loading || error) {
        return (
            <div>
                {loading && <Loader />}
                {error && (
                    <div>{`There is a problem fetching the post data - ${error}`}</div>
                )}
            </div>
        )
    }
    else {
        let dataSelectedTeam = [];

        switch (props.selectedTeam) {
            case "Team 1":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[0].costs.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[0].freelancer.slice(0, 12);
                        chartMax = 750000;
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[0].costs.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[0].freelancer.slice(12, 24);
                        chartMax = 775000;
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[0].costs.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[0].freelancer.slice(24, 36);
                        chartMax = 800000;
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[0].costs.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[0].freelancer.slice(36, 48);
                        chartMax = 850000;
                        break;
                    default:
                }
                break;
            case "Team 2":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[1].costs.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[1].freelancer.slice(0, 12);
                        chartMax = 750000;
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[1].costs.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[1].freelancer.slice(12, 24);
                        chartMax = 775000;
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[1].costs.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[1].freelancer.slice(24, 36);
                        chartMax = 800000;
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[1].costs.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[1].freelancer.slice(36, 48);
                        chartMax = 850000;
                        break;
                    default:
                }
                break;
            case "Team 3":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[2].costs.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[2].freelancer.slice(0, 12);
                        chartMax = 750000;
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[2].costs.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[2].freelancer.slice(12, 24);
                        chartMax = 775000;
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[2].costs.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[2].freelancer.slice(24, 36);
                        chartMax = 800000;
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[2].costs.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[2].freelancer.slice(36, 48);
                        chartMax = 850000;
                        break;
                    default:
                }
                break;
            case "Team 4":
                switch (selectedYear) {
                    case "2019":
                        dataSelectedTeam[0] = fetchedData[3].costs.slice(0, 12);
                        dataSelectedTeam[1] = fetchedData[3].freelancer.slice(0, 12);
                        chartMax = 750000;
                        break;
                    case "2020":
                        dataSelectedTeam[0] = fetchedData[3].costs.slice(12, 24);
                        dataSelectedTeam[1] = fetchedData[3].freelancer.slice(12, 24);
                        chartMax = 775000;
                        break;
                    case "2021":
                        dataSelectedTeam[0] = fetchedData[3].costs.slice(24, 36);
                        dataSelectedTeam[1] = fetchedData[3].freelancer.slice(24, 36);
                        chartMax = 800000;
                        break;
                    case "2022":
                        dataSelectedTeam[0] = fetchedData[3].costs.slice(36, 48);
                        dataSelectedTeam[1] = fetchedData[3].freelancer.slice(36, 48);
                        chartMax = 850000;
                        break;
                    default:
                }
                break;
            default:
        }

        const data = {
            labels: ['Jan.', 'Feb.', 'Mrz.', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'],
            datasets: [
                {
                    label: 'Gemeinkosten & Personalkosten',
                    data: dataSelectedTeam[0],
                    backgroundColor: 'rgba(228, 35, 33, 0.5)',
                    borderColor: 'rgba(228, 35, 33, 1)',
                    borderWidth: 3,
                },
                {
                    label: 'Freelancer',
                    data: dataSelectedTeam[1],
                    borderColor: 'rgba(3, 141, 175, 1)',
                    backgroundColor: 'rgba(3, 141, 175, 0.5)',
                    borderWidth: 3,
                }
            ]
        };

        const options = {
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        footer: footer,
                    }
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                    }
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Monat',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Euro',
                    },
                    stacked: true,
                    suggestedMin: 0,
                    suggestedMax: chartMax,
                    ticks: {
                        stepSize: 100000,
                    }
                }
            }
        }

        return (
            <div className="card card-chart">
                <div className="card-header">
                    <p>Kosten {props.selectedTeam}</p>
                    <select value={selectedYear} onChange={handleChangeYearSelect}>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                </div>
                <div className="card-body">
                    <div className="chart-area">
                        <Bar data={data} options={options} />
                    </div>
                </div>
            </div>
        )
    }
}

export default FinanzenKostenStackedBarChart;
